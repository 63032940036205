import React from "react";
import { graphql } from "gatsby";

export default function Menu({ data }) {
  return <></>;
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query ($place: String!, $language: String!) {
    menu: sanityKulinarik(place: { eq: $place }, language: { eq: $language }) {
      id
      place
      title
      _rawMenuInfo(resolveReferences: { maxDepth: 10 })
      menuSections {
        title
        menuInfoSection {
          price
          title
          info
          bio
          vegan
          vegetarian
        }
      }
      _rawMenuInfo2(resolveReferences: { maxDepth: 10 })
      menuSections2 {
        title
        menuInfoSection {
          price
          title
          info
          bio
          vegan
          vegetarian
        }
      }
    }

    queryShowHidePlaces: allSanityShowPlacesProgramm {
      nodes {
        klubShowProgramm
        klubShowLocation
        klubShowMieten
        saalShow
        wiengartenShow
        biergartenShow
      }
    }
  }
`;
